<template>
  <div class="flex justify-between col-start-1 col-end-3 overflow-y-auto">
    <div class="flex flex-col w-7/12">
      <div class="flex flex-col mt-6 relative">
        <span
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
          >{{ $t('name') }}</span
        >
        <el-input
          class="mt-3"
          type="text"
          v-model="unit.name"
          :class="{ 'input-error': isNameMissing }"
        />
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isNameMissing"
          >
            {{ $t('notifications.enterName') }}
          </div>
        </transition>
      </div>
      <div class="flex flex-col mt-6 relative">
        <span
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
          >{{ $t('objects.type') }}</span
        >
        <el-select
          class="mt-3"
          v-model="unit.type.key"
          :class="{ 'input-error': isUnitTypeMissing }"
        >
          <el-option
            v-for="item in unitTypes"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isUnitTypeMissing"
          >
            {{ $t('objects_tabs.main.type.error') }}
          </div>
        </transition>
      </div>
      <div class="flex flex-col mt-6 relative">
        <div class="flex justify-between items-center">
          <span
            class="text-annotationColor font-SourceSansPro text-sm font-semibold"
            >{{ $t('objects.device') }}</span
          >
          <el-tooltip
            v-if="isPortInfo"
            :open-delay="700"
            effect="dark"
            placement="bottom"
            :offset="0"
            :visible-arrow="false"
          >
            <div class="flex flex-col" slot="content">
              <span>{{ $t('objects_server') + portInfo.server }}</span>
              <span>{{ $t('objects_port') + portInfo.port }}</span>
            </div>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 13.6364C11.0773 13.6364 13.6364 11.0773 13.6364 7.5C13.6364 3.9227 11.0773 1.36364 7.5 1.36364C3.9227 1.36364 1.36364 3.9227 1.36364 7.5C1.36364 11.0773 3.9227 13.6364 7.5 13.6364ZM7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15ZM4.96388 5.62987C5.01397 4.36642 5.87668 3.40909 7.49078 3.40909C8.96017 3.40909 9.95089 4.28293 9.95089 5.47403C9.95089 6.33673 9.51676 6.94341 8.78206 7.38312C8.06407 7.80612 7.85813 8.10111 7.85813 8.6744V9.01392H6.706L6.70043 8.56865C6.6726 7.78386 7.01212 7.28293 7.77464 6.82653C8.44811 6.42022 8.68744 6.0974 8.68744 5.52412C8.68744 4.89518 8.19208 4.43321 7.42956 4.43321C6.66147 4.43321 6.16611 4.89518 6.11601 5.62987H4.96388ZM7.29041 11.5909C6.8841 11.5909 6.56128 11.2792 6.56128 10.8729C6.56128 10.4666 6.8841 10.1549 7.29041 10.1549C7.70785 10.1549 8.0251 10.4666 8.0251 10.8729C8.0251 11.2792 7.70785 11.5909 7.29041 11.5909Z"
                fill="#2F80ED"
              />
            </svg>
          </el-tooltip>
        </div>
        <el-select
          class="mt-3"
          v-model="unit.terminal_type.key"
          :class="{ 'input-error': isTerminalTypeMissing }"
          @change="$emit('terminal-type-change')"
        >
          <el-option
            class="text-ellipsis"
            style="max-width: 209px"
            v-for="item in terminalTypes"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isTerminalTypeMissing"
          >
            {{ $t('objects_tabs.main.device.error') }}
          </div>
        </transition>
      </div>
      <div class="flex flex-col mt-6 relative">
        <div class="flex justify-between items-center">
          <span
            class="text-annotationColor font-SourceSansPro text-sm font-semibold"
            >{{ $t('objects.imei') }}</span
          >
          <img
            class="cursor-pointer"
            src="@/assets/icons/copy.svg"
            style="width: 16px"
            @click="copyIMEI"
          />
        </div>
        <el-select
          class="mt-3"
          v-model="unit.imei"
          :class="{ 'input-error': isTerminalTypeMissing }"
          filterable="filterable"
          :allow-create="
            role === 'SUPERVISOR' ||
            role === 'TARIFF_EDITOR' ||
            role === 'CONTROLLER'
          "
        >
          <el-option
            class="text-ellipsis"
            style="max-width: 209px"
            v-for="item in unitImeis"
            :key="item.id"
            :label="item.imei"
            :value="item.imei"
          />
        </el-select>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isImeiMissing"
          >
            {{ $t('objects_tabs.main.id.error') }}
          </div>
        </transition>
      </div>
      <div class="flex flex-col mt-6 relative">
        <span
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
          >{{ $t('objects_phone1') }}</span
        >
        <el-input class="mt-3" type="text" v-model="unit.phonenumber" />
        <!-- <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isPhonenumberMissing"
          >
            {{ $t('objects_tabs.main.phone.error') }}
          </div>
        </transition> -->
      </div>
      <div class="flex flex-col mt-6">
        <span
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
          >{{ $t('objects_phone2') }}</span
        >
        <el-input class="mt-3" type="text" v-model="unit.phonenumber2" />
      </div>
    </div>

    <div class="flex flex-col items-end">
      <!-- Создание объекта -->
      <el-popover v-if="title === 1" trigger="click" v-model="isVisible">
        <car-icons @select-icon="selectIcon" :unit="unit" />

        <span slot="reference">
          <span
            class="mt-10 mr-4 w-28 h-28 rounded-full flex"
            slot="reference"
            v-if="unit.icon.key !== ''"
            style="
              border: 5px solid #d7e0e8;
              width: 100px;
              height: 100px;
              justify-content: center;
              align-items: center;
            "
          >
            <svg
              v-if="unit.icon.key !== ''"
              width="110"
              height="110"
              viewBox="0 -4 56 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use
                :xlink:href="`static/car_icon-new/cars.svg#${currentIcon}`"
                :fill="currentColor"
              />
            </svg>
          </span>
          <span
            class="mt-10 mr-4 w-28 h-28 rounded-full flex"
            slot="reference"
            v-else="unit.icon.key !== ''"
            style="
              border: 5px solid #d7e0e8;
              width: 100px;
              height: 100px;
              justify-content: center;
              align-items: center;
            "
          >
            <svg
              width="110"
              height="110"
              viewBox="0 -4 56 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <use
                :xlink:href="`static/car_icon-new/cars.svg#car2`"
                fill="#eb5757"
              />
            </svg>
          </span>
        </span>
      </el-popover>

      <!-- Редактирование объекта -->
      <el-popover v-else trigger="click" placement="bottom" v-model="isVisible">
        <car-icons @select-icon="selectIcon" :unit="unit" />
        <span
          class="mt-10 mr-4 w-28 h-28 rounded-full flex"
          @click="$emit('checkme')"
          slot="reference"
          v-if="unit.icon.key !== ''"
          style="
            border: 5px solid #d7e0e8;
            width: 100px;
            height: 100px;
            justify-content: center;
            align-items: center;
          "
        >
          <svg
            width="110"
            height="110"
            viewBox="0 -4 56 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use
              :xlink:href="`static/car_icon-new/cars.svg#${currentIcon}`"
              :fill="currentColor"
            />
          </svg>
        </span>
        <svg
          class="mt-10 mr-4 cursor-pointer"
          v-else
          slot="reference"
          width="110"
          height="110"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="55" cy="55" r="55" fill="#CFDBEB" />
          <path
            d="M83.784 46.986h-1.925L68.77 30.736a7.694 7.694 0 00-2.678-2.114 7.747 7.747 0 00-3.335-.755H37.022a7.699 7.699 0 00-7.15 4.807l-5.764 14.582c-3.315.853-5.774 3.818-5.774 7.377v13.384c0 1.056.86 1.912 1.925 1.912h5.774c0 6.335 5.171 11.471 11.55 11.471 6.38 0 11.55-5.136 11.55-11.471h15.4c0 6.335 5.171 11.471 11.55 11.471 6.38 0 11.55-5.136 11.55-11.471h5.776a1.918 1.918 0 001.924-1.912v-9.56c0-6.335-5.17-11.471-11.55-11.471zm-46.2 28.678c-3.185 0-5.776-2.572-5.776-5.735 0-3.163 2.59-5.736 5.776-5.736 3.184 0 5.774 2.573 5.774 5.736s-2.59 5.735-5.774 5.735zm8.662-28.678H32.402l4.62-11.472h9.224v11.472zm5.775 0V35.514h10.737l9.24 11.472H52.02zm24.063 28.678c-3.185 0-5.776-2.572-5.776-5.735 0-3.163 2.59-5.736 5.775-5.736 3.185 0 5.776 2.573 5.776 5.736s-2.59 5.735-5.775 5.735z"
            fill="#fff"
          />
        </svg>
      </el-popover>
      <transition name="el-zoom-in-top">
        <div class="text-notify text-xs mt-4" v-if="isIconMissing">
          {{ $t('objects_noPicture') }}
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { copyTextToClipboard } from '@/utils/helpers';

export default {
  components: {
    carIcons: () => import('.././carIcons.vue')
  },
  props: [
    'unit',
    'isNameMissing',
    'isUnitTypeMissing',
    'isTerminalTypeMissing',
    'unitImeis',
    'unitTypes',
    'terminalTypes',
    'isPhonenumberMissing',
    'isImeiMissing',
    'title',
    'ports',
    'isIconMissing',
    'role'
  ],
  data() {
    return {
      isVisible: false,
      isPortInfo: false,
      portInfo: '',
      colors: [
        {
          name: 'red',
          color: '#eb5757'
        },
        {
          name: 'green',
          color: '#1bb934'
        },
        {
          name: 'blue',
          color: '#1585d8'
        },
        {
          name: 'grey',
          color: '#808080bf'
        },
        {
          name: 'orange',
          color: '#e68a2f'
        }
      ],
      currentIcon: 'car2',
      currentColor: 'red'
    }
  },

  created() {
    console.log('wok', this.role)
    // Устанока иконки при загрузке
    let arrIcon = this.unit.icon.key.split('_')
    let color = arrIcon.pop()
    this.colors.forEach((el) => {
      if (el.name === color) {
        this.currentColor = el.color
      }
    })

    let index = this.unit.icon.key.lastIndexOf('_')
    this.currentIcon = this.unit.icon.key.substring(0, index)

    this.ports.forEach((port) => {
      if (port.terminal === this.unit.terminal_type.key) {
        this.portInfo = port
        this.isPortInfo = true
      }

      // this.unit.terminal_type.key === port.terminal
      //   ? (this.portInfo = port) && this.isPortInfo == true
      //   : (this.isPortInfo = false)
    })
  },

  computed: {
    ...mapGetters({
      unitIcon: 'dictionary/unitIcon'
    })
  },

  watch: {
    'unit.terminal_type.key'(val) {
      this.ports.forEach((port) => {
        if (port.terminal === this.unit.terminal_type.key) {
          this.portInfo = port
          this.isPortInfo = true
        }
      })
    }
  },

  methods: {
    async copyIMEI() {
      await copyTextToClipboard(this.unit.imei)
      this.$notify.success({
        message: this.$t('copy'),
        position: 'top-left'
      })
    },

    selectIcon(icon, color) {
      this.isVisible = false
      this.colors.forEach((el) => {
        if (el.name === color) {
          this.currentColor = el.color
        }
      })
      this.currentIcon = icon

      // Значение иконки для отправки на сервер
      this.unit.icon.key = `${icon}_${color}`
    },

    checkme() {
      this.isVisible = true
    }
  }
}
</script>
